import {createSlice} from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'mainContent',
  initialState: {
    sliderCount: 0,
    selectedProvider: '',
    selectedActionKey: null
  },
  reducers: {
    setSliderCount: (state, {payload}) => {
      state.sliderCount = payload;
    },
    setSelectorProvider: (state, {payload}) => {
      state.selectedProvider = payload;
    },
    setSelectedActionKey: (state, {payload}) => {
      state.selectedActionKey = payload;
    },
  },
});

export function onChangeSlide(direction, list, sliderCount) {
  const count = sliderCount + direction;
  const slides = list.length - 1;
  return async (dispatch) => {
    if (count < 0) {
      dispatch(setSliderCount(slides));
    } else if (count > slides) {
      dispatch(setSliderCount(0));
    } else {
      dispatch(setSliderCount(count));
    }
  };
}

export const {setSliderCount, setSelectorProvider, setSelectedActionKey} = slice.actions;

export const mainContentSelector = (state) => state.mainContent;

export default slice.reducer;
